import * as React from "react";
import { Link } from "gatsby";
import notfound from "../assets/images/not_found.svg";
import access_denied from "../assets/images/access_denied.svg";
import { Footer, Header } from "../layout/landing";

const NotFoundPage = () => {
  return (
    <>
      <div className="mb-24 md:mb-32">
        <Header />
      </div>
      <div className="flex flex-col h-2/3 justify-between items-center px-10" id="#">
        <div>
          <img
            className="hidden lg:block h-96"
            src={notfound}
            alt="not found"
          />
          <img
            className="lg:hidden mx-auto max-h-96"
            src={access_denied}
            alt="not found"
          />
          <p className="text-coolGray-600 text-md md:text-2xl py-8 text-center">
            It seems that you've wandered too far my friend.{" "}
            <Link className="hover:text-secondary underline font-medium" to="/">
              Go home
            </Link>
          </p>
        </div>
      </div>
      <div>
        <div className="absolute left-0 right-0 bottom-0">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
